import React from 'react';
import './home.scss';

export default function Home() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Home</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <div className={'logos-container'}>   
          </div>
          <p className='l1'>Cảm ơn các bạn đã sử dụng ứng dụng hỗ trợ thông tin</p>
          <div>
            <span className='l2'>Vì mục đích bảo mật không chia sẻ các thông tin tài khoản của bạn cho người khác</span><br/><br/>
            <span className='l3'> Ứng dụng sẽ hỗ trợ các thông tin : </span><br/><br/>
            <div className='l4'>
              <span>  1 . Thay đổi đăng ký suất ăn mặc định</span><br/><br/>
              <span>  2 . Đăng ký nghỉ phép</span><br/><br/>
              <span>  3 . Ký hợp đồng online</span><br/><br/>
              <span>  4 . Kiểm tra thông tin ngày công</span><br/><br/>
              <span>  5 . Kiểm tra phiếu lương tháng</span><br/><br/>
              <span>  6 . Báo cáo công việc</span><br/><br/>
            </div>
          </div>
          <span>Vui lòng liên hệ hotline :</span> 
          <a href='19004567'>19004567</a>
          <span> để được hỗ trợ khi gặp bất cứ vấn đề gì</span>
        </div>
      </div>
    </React.Fragment>
)}
