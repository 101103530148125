import React from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {Column,Pager,Paging,FilterRow,Editing,Form,Item
} from 'devextreme-react/data-grid';
import server from '../../api/configss';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import authHeader from '../../api/auth-header';
import notify from 'devextreme/ui/notify';
import {salaAdvance} from '../../api/user_api'
export default function SalaAdvance() {

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Xác nhận ứng lương</h2>

      <DataGrid
        className={'dx-card wide-card'}
        dataSource={Shiftmeal_infor}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true} onEditingStart={onEditingStart} onSaved={onSaved}
        dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <Editing
            mode="form"
            useIcons={true}
            allowUpdating={true}
        >
            <Form showBorders="true">
                <Item itemType="group" colCount={2} colSpan={2}>
                    <Item dataField="sala_emcode" />
                    <Item dataField="em_fullname" />
                    <Item dataField="sala_date" />
                    <Item dataField="sala_amount" />
                </Item>
            </Form>
        </Editing>
                
        <Column 
            dataField={'sala_id'} 
            width={90} 
            visible ={false}
        />
        <Column
          dataField={'sala_emcode'}
          width={90}
          caption={'Mã NV'}
          hidingPriority={2}
          editorOptions={editField}
        />
        <Column
          dataField={'em_fullname'}
          caption={'Họ tên'}
          hidingPriority={1}
          editorOptions={editField}
        />
        <Column
          dataField={'sala_date'}
          caption={'Ngày Ứng.'}
          hidingPriority={4}
          dataType="date" format="dd-MM-yyyy"
          editorOptions={editField}
        />
        <Column
          dataField={'sala_hour'}
          caption={'Giờ Công'}
          hidingPriority={3}
          editorOptions={editField}
        />
        <Column
          dataField={'sala_amount'}
          caption={'Số Tiền'}
          hidingPriority={4}
          format={AmountFormat}
          editorOptions={editField}
        />
      </DataGrid>
    </React.Fragment>
)}
function User() {
    try {
        return JSON.parse(localStorage.getItem("userEm"));
    }
    catch {
        return null;
    }
}

const url = server + '/api/data/sala_advance';
const editField = { readOnly: true }
const AmountFormat = { style: 'currency', currency: 'VND', useGrouping: true, minimumSignificantDigits: 3 };

const Shiftmeal_infor = createStore({
    key: 'sala_id',
    loadUrl: `${url}`,
    insertUrl: `${url}insert`,
    deleteUrl: `${url}delete/`,
    updateUrl: `${url}put/`,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = authHeader();
        if (method === 'load') {
            ajaxOptions.url = `${url}/${User().username}`
        }
        if (method === 'insert') {
            ajaxOptions.data = JSON.parse(ajaxOptions.data.values)
        }
        if (method === 'update') {
            ajaxOptions.url += ajaxOptions.data.key;
            ajaxOptions.data = JSON.parse(ajaxOptions.data.values)
        }
        if (method === 'delete') {
            ajaxOptions.url += ajaxOptions.data.key;
        }
    }
}
);
let sala_id = undefined;
const onEditingStart= (e) => {
  sala_id = e.data.sala_id
  if (e.data.sala_status > 0) {
    notify('Thông tin này đã được xác thực', 'error', 2000);
    e.cancel = true;
    return;
  }
  if (e.data.edit !==1) {
    notify('Đã hết hạn xác nhận ứng', 'error', 2000);
    e.cancel = true;
    return;
  }
};
const onSaved= async (e) => {
  const result = await salaAdvance(sala_id,User().username)
  if (result.isOk) {
    notify(result.message, 'success', 2500);
    window.location.reload();
  } else {
    notify(result.message, 'error', 2000);
  }   
};
