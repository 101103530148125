// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form .link {
  text-align: center;
  font-size: 16px;
  font-style: normal;
}
.login-form .link a {
  text-decoration: none;
}
.login-form .form-text {
  margin: 10px 0;
  color: rgba(0, 0, 0, 0.6094117647);
}`, "",{"version":3,"sources":["webpack://./src/components/login-form/LoginForm.scss"],"names":[],"mappings":"AAGE;EACE,kBAAA;EACA,eAAA;EACA,kBAAA;AAFJ;AAII;EACE,qBAAA;AAFN;AAME;EACE,cAAA;EACA,kCAAA;AAJJ","sourcesContent":["@import \"../../themes/generated/variables.base.scss\";\n\n.login-form {\n  .link {\n    text-align: center;\n    font-size: 16px;\n    font-style: normal;\n\n    a {\n      text-decoration: none;\n    }\n  }\n\n  .form-text {\n    margin: 10px 0;\n    color: rgba($base-text-color, alpha($base-text-color) * 0.7);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
