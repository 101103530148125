import axios from "axios";
import server from './configss';
import authHeader from './auth-header';
const API_URL = server + "/api/data/";

export async function salaAdvance(sala_id, sala_emcode) {
    return axios
      .put(API_URL + `sala_advanceput/`+ sala_id + `/` + sala_emcode, {sala_status:1,sala_user:sala_emcode},{ headers: authHeader()})
      .then((response) => {
        if (response.status === 400) {
          return {
            isOk: false,
            message: "Kết nối server thất bại. Có lỗi kết nối"
          }
        }
        if (response.data.mode ===1 ) {
          return {
            isOk: true,
            message: response.data.message
          }
        }
        else {
          return {
            isOk: false,
            message: response.data.message
          };
        }
      }
      )
      .catch(function (error) {
        console.log(error,'loi')
        return {
          isOk: false,
          message: "Kết nối server thất bại. Có lỗi kết nối"
        }
      })
}
export  function getAttabsum(em_code) {
    return  axios.get(API_URL + `employee_profile/${em_code}`, { headers: authHeader() });
}
export  function getHomepage(em_code) {
  return  axios.get(API_URL + `employee_profile/${em_code}`, { headers: authHeader() });
}
export  function geturlImage(url_code,url_type) {
  return  axios.get(server + `/api/data/url_sheet_calendar/${url_code}/${url_type}`, { headers: authHeader() });
}