// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
}
.logos-container svg {
  display: inline-block;
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.l1 {
  font-size: x-large;
  color: blue;
}

.l2 {
  font-size: large;
  color: rgb(217, 25, 64);
}

.l3 {
  font-size: large;
  color: white;
  background-color: blue;
}

.l4 {
  border-radius: 3mm;
}

.screen-x-small .logos-container svg {
  width: 100%;
  display: block;
}
.screen-x-small .logos-container svg.plus {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/home.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,kBAAA;AACF;AAAE;EACE,qBAAA;AAEJ;;AAEA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;AACF;;AACA;EACE,gBAAA;EACA,uBAAA;AAEF;;AAAA;EACE,gBAAA;EACA,YAAA;EACA,sBAAA;AAGF;;AADA;EACE,kBAAA;AAIF;;AADE;EACE,WAAA;EACA,cAAA;AAIJ;AAHI;EACE,SAAA;AAKN","sourcesContent":[".logos-container {\n  margin: 20px 0 40px 0;\n  text-align: center;\n  svg {\n    display: inline-block;\n  }\n}\n\n.devextreme-logo {\n  width: 200px;\n  height: 34px;\n  margin-bottom: 19px;\n}\n\n.react-logo {\n  width: 184px;\n  height: 68px;\n}\n\n.l1 {\n  font-size:x-large;\n  color: blue;\n}\n.l2 {\n  font-size: large;\n  color: rgb(217, 25, 64);\n}\n.l3 {\n  font-size: large;\n  color: white;\n  background-color: blue;\n}\n.l4 {\n  border-radius: 3mm;\n}\n.screen-x-small .logos-container {\n  svg {\n    width: 100%;\n    display: block;\n    &.plus {\n      margin: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
