import { Routes, Route, Navigate } from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, ResetPasswordForm, ChangePasswordForm, CreateAccountForm } from './components';

export default function UnauthenticatedContent() {
  return (
    <Routes>
      <Route
        path='/login' 
        element={
          <SingleCard title="Đăng Nhập">
            <LoginForm />
          </SingleCard>
        }
      />
      <Route
        path='/create-account'
        element={
          <SingleCard title="Yêu Cầu Tài Khoản">
            <CreateAccountForm />
          </SingleCard>
        }
      />
      <Route 
        path='/reset-password'
        element={
          <SingleCard
            title="Cấp Lại Mật Khẩu"
            description="Vui lòng nhập chính xác thông tin dưới và gửi yêu cầu"
          >
            <ResetPasswordForm />
          </SingleCard>
        }
      />
      <Route
        //path='/change-password/:recoveryCode'
        path='/change-password'
        element={
          <SingleCard title="Đổi Mật Khẩu">
            <ChangePasswordForm />
          </SingleCard>
        }
      />
      <Route path='*' element={<Navigate to={'/login'} />}></Route>
    </Routes>
  );
}
