import http from './http-common'
import axios from 'axios';
import authHeader from "../api/auth-header";
import authHeaderFile from '../api/auth-headerF'
import server from '../api/configss';
class UploadFiles {
  upload(url,file,to_folder,url_name,url_file,url_code, url_type,url_user) {
    let _url = server + url;
    console.log("checkfile",file)
    let formData = new FormData();
    formData.append("url_code", url_code);
    formData.append("url_type", url_type);
    formData.append("to_folder", to_folder);
    formData.append("url_name", url_name);
    formData.append("url_file", url_file);
    formData.append("url_user", url_user);
    formData.append("file", file);
    return http.post(_url, formData, {headers: authHeaderFile()});
  }
  sendfile(file) {
    let formData = new FormData();
    formData.append("file", file);
    console.log("checkfile",file)

    return http.post("http://103.9.156.40:8000/detect2line", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      //onUploadProgress,
    });
  }
  getFiles(url_code, url_type) {
    return axios.get(`/premium/filesurl/${url_code}/${url_type}`, { headers: authHeader() });

  }
  getFiles_checkSheet(url_code, url_type) {
    return axios.get(`/premium/filesurl_checkSheet/${url_code}/${url_type}`, { headers: authHeader() });
  }
}
export default new UploadFiles();
