import React from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {Column,Pager,Paging,FilterRow,Lookup,Editing,Form,Item
} from 'devextreme-react/data-grid';
import server from '../../api/configss';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import authHeader from '../../api/auth-header';
import notify from 'devextreme/ui/notify';

export default function Shiftmeal() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Lựa chọn xuất ăn</h2>

      <DataGrid
        className={'dx-card wide-card'}
        dataSource={Shiftmeal_infor}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true} onEditingStart={onEditingStart}
        dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <Editing
            mode="form"
            useIcons={true}
            allowUpdating={true}
        >
            <Form showBorders="true">
                <Item itemType="group" colCount={2} colSpan={2}>
                    <Item dataField="plan_emcode" />
                    <Item dataField="em_fullname" />
                    <Item dataField="plan_workdate" />
                    <Item dataField="plan_meal" />
                </Item>
            </Form>
        </Editing>
                
        <Column 
            dataField={'plan_id'} 
            width={90} 
            visible ={false}
        />
        <Column
          dataField={'plan_emcode'}
          width={190}
          caption={'Mã NV'}
          hidingPriority={2}
          editorOptions={editField}
        />
        <Column
          dataField={'em_fullname'}
          caption={'Họ tên'}
          hidingPriority={1}
          editorOptions={editField}
        />
        <Column
          dataField={'plan_workdate'}
          caption={'Ngày LV.'}
          hidingPriority={4}
          dataType="date" format="dd-MM-yyyy"
          editorOptions={editField}
        />
        <Column
          dataField={'plan_shifname'}
          caption={'Ca'}
          hidingPriority={3}
          editorOptions={editField}
        />
        <Column
          dataField={'plan_meal'}
          caption={'Xuất Ăn'}
          allowSorting={false}
          hidingPriority={4}
        >
          <Lookup
            dataSource={priorities}
            valueExpr={'value'}
            displayExpr={'name'}
          />
        </Column>
        
      </DataGrid>
    </React.Fragment>
)}
function User() {
    try {
        return JSON.parse(localStorage.getItem("userEm"));
    }
    catch {
        return null;
    }
}
const url = server + '/api/data/meal_shiftem';
const editField = { readOnly: true }
const Shiftmeal_infor = createStore({
    key: 'plan_id',
    loadUrl: `${url}`,
    insertUrl: `${url}insert`,
    deleteUrl: `${url}delete/`,
    updateUrl: `${url}put/`,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = authHeader();
        if (method === 'load') {
            ajaxOptions.url = `${url}/${User().username}`
        }
        if (method === 'insert') {
            ajaxOptions.data = JSON.parse(ajaxOptions.data.values)
        }
        if (method === 'update') {
            ajaxOptions.url += ajaxOptions.data.key;
            ajaxOptions.data = JSON.parse(ajaxOptions.data.values)
        }
        if (method === 'delete') {
            ajaxOptions.url += ajaxOptions.data.key;
        }
    }
}
);
const onEditingStart= (e) => {
  if (e.data.edit !==1) {
    notify('Đã hết hạn điều chỉnh xuất ăn', 'error', 2000);
    e.cancel = true;
  }
};
/* const dataSource = {
  store: {
    version: 2,
    type: 'odata',
    key: 'Task_ID',
    url: 'https://js.devexpress.com/Demos/DevAV/odata/Tasks'
  },
  expand: 'ResponsibleEmployee',
  select: [
    'Task_ID',
    'Task_Subject',
    'Task_Start_Date',
    'Task_Due_Date',
    'Task_Status',
    'Task_Priority',
    'Task_Completion',
    'ResponsibleEmployee/Employee_Full_Name'
  ]
}; */

const priorities = [
  { name: 'Cơm', value: 'M1' },
  { name: 'Nước', value: 'M2' },
  { name: 'Chay', value: 'M3' },
];
