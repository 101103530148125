import React, { useState, useRef, useCallback } from 'react';
//import { useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';

export default function ChangePassword() {
    const { user,changePass } = useAuth();
    //const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const formData = useRef({ em_code: user.username, em_pass: '', em_newpass: '' });

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        const { em_code, em_pass, em_newpass } = formData.current;
        setLoading(true);
        const result = await changePass(em_code, em_pass, em_newpass);
        setLoading(false);

        if (result.isOk) {
            notify(result.message)
        } else {
            notify(result.message, 'error', 2000);
        }
    }, [changePass]);
    const confirmPassword = useCallback(
        ({ value }) => value === formData.current.em_newpass,
        []
    );

    return (
        <div>
            <div>
                <h6 className='instructionreport'>  Đổi mật khẩu</h6>
            </div>
            <form onSubmit={onSubmit}>
                <Form formData={formData.current} disabled={loading}>
                    <Item
                        dataField={'em_code'}
                        editorType={'dxTextBox'}

                        editorOptions={usernameEditorOptions}

                    >
                        <RequiredRule message="User name is required" />
                        <Label visible={false} />
                    </Item>
                    <Item
                        dataField={'em_pass'}
                        editorType={'dxTextBox'}
                        editorOptions={currentpassEditorOptions}
                    >
                        <RequiredRule message="Current password is required" />
                        <Label visible={false} />
                    </Item>
                    <Item
                        dataField={'em_newpass'}
                        editorType={'dxTextBox'}
                        editorOptions={passwordEditorOptions}
                    >
                        <RequiredRule message="Password is required" />
                        <Label visible={false} />
                    </Item>
                    <Item
                        dataField={'confirmedPassword'}
                        editorType={'dxTextBox'}
                        editorOptions={confirmedPasswordEditorOptions}
                    >
                        <RequiredRule message="Password is required" />
                        <CustomRule
                            message={'Passwords do not match'}
                            validationCallback={confirmPassword}
                        />
                        <Label visible={false} />
                    </Item>
                    <ButtonItem>
                        <ButtonOptions
                            width={'140'}
                            type={'default'}
                            useSubmitBehavior={true}
                        >
                            <span className="dx-button-text">
                                {
                                    loading
                                        ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                        : 'Lưu'
                                }
                            </span>
                        </ButtonOptions>
                    </ButtonItem>
                </Form>
            </form>
        </div>
    );
}
const usernameEditorOptions = { stylingMode: 'filled', placeholder: 'Mã NV', mode: 'username', readOnly: true };
const currentpassEditorOptions = { stylingMode: 'filled', placeholder: 'Mật khẩu hiện tại', mode: 'password' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Mật khẩu mới', mode: 'password' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Nhập lại mật khẩu mới', mode: 'password' };