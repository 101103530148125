export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Employee Infor.',
    icon: 'user',
    items: [
      {text: 'Đăng ký suất ăn',path: '/shiftmeal'},
      {text: 'Đăng ký nghỉ phép',path: '/anualeave'},
      {text: 'Ngày công',path: '/attinoutlogs'},
      {text: 'Ứng lương',path: '/sala_advance'},
      {text: 'Phiếu lương',path: '/tasks2'},
      {text: 'Lịch làm việc',path: '/checksheet'},
    ]
  },
  {
    text: 'Vender portal',
    icon: 'event',
    items: [
      {
        text: 'Employees infor',
        icon :'increaseindent',
        path: '/profile1'
      },
      {
        text: 'Daily attendance',
        icon :'increaseindent',
        path: '/tasks4'
      },
    ]
  },
  {
    text: 'System user config',
    icon: 'folder',
    items: [
      {
        text: 'Đổi mật khẩu',
        path: '/changepass'
      },
    ]

  }
  ];
