import React, {useState,useCallback,useRef } from 'react';
import Accordion from 'devextreme-react/accordion';
import CustomTitle from './CustomTitle.js';
//import CustomItem from './CustomItem.js';
import {UsecomInfor} from '../../contexts/commonContex.js'
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import Parser from 'html-react-parser';
import socket from '../../api/socket_io';
import './styles.css'
function Visibe(val){
  if (val ==0 || val==='0'){
    return false;
  }
  else{
    return true
  }
  };
  function User() {
    try {
        return JSON.parse(localStorage.getItem("userEm"));
    }
    catch {
        return null;
    }
  }
  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day,month,year].join('/');
}
function ChangeRead(data){
  if (data.read ==1){
    return './padlock-5-16.png';
  }
  else{
    return './padlock-7-16.png'
  }
}
export default function Reminder() {
  const commdata = UsecomInfor();
  const [selectedItems,setSelectedItems] = useState([]);
  const reRef = useRef(null);
  const selectionChanged = useCallback(
    (e) => {
      let newItems = [...selectedItems];
      e.removedItems.forEach((item) => {
        const index = newItems.indexOf(item);
        if (index >= 0) {
          newItems.splice(index, 1);
        }
      });
      if (e.addedItems.length) {
        newItems = [...newItems, ...e.addedItems];
      }
      
      setSelectedItems(newItems);
    },
    [selectedItems,setSelectedItems],
  );
  const readClick = useCallback(
    () => {
      let newItems = [...selectedItems];
      newItems = [...newItems, ...reRef.current.props.selectedItems];
      newItems[0].read = 1 ;
      setSelectedItems(newItems);
    },
    [selectedItems,setSelectedItems]
  );
  const Custom = useCallback((data)=>{
    return (
    <div>
        <div>
          <p>
            <b>{Parser(data.re_content)}</b>
            <br/>
            <b>Có hiệu lực từ: {formatDate(data.re_startdate)} Tới: {formatDate(data.re_expried)}</b>
          </p>
        </div>
        <Toolbar className='toolbarreminder'>
          <Item location='after' visible={Visibe(data.re_type)}>
              <Button icon="todo" stylingMode='text' text='Open->'  />
          </Item>
          <Item location='after' visible={!Visibe(data.read)}>
              <Button icon="./padlock-5-16.png" stylingMode='text' text='OK' onClick={()=>{socket.emit("update_reminder_read",{re_id:data.re_id,username:User().username});readClick();}}/>
          </Item>
          <Item location='after' visible={false}>
              <Button icon="close" stylingMode='text' text='Close'  />
          </Item>
        </Toolbar>
      </div>
    )
  }
  ,
    [],
  );
  return (
    <React.Fragment>
       <div id='accordion'>
      <Accordion
        ref={reRef}
        dataSource={commdata.Reminder}
        collapsible={true}
        multiple={false}
        animationDuration={500}
        selectedItems={selectedItems}
        onSelectionChanged={selectionChanged}
        itemTitleRender={CustomTitle}
        itemRender={Custom}//CustomItem}
        id="accordion-container"
      />
    </div>
    </React.Fragment>
  )
}