export { default as HomePage } from './home/home';
export { default as ProfilePage } from './profile/profile';
export { default as TasksPage } from './tasks/tasks';
export { default as ChangePasswordpage } from './userSystem/changepass'
export {default as Shiftmealpage} from './shiftmeal/shiftmeal'
export {default as SalaAdvancepage} from './sala_advance/sala_advance'
export {default as AnnuaLeavepage} from './anualeave/anualeave'
export {default as Attinoutlogspage} from './attinoutlogs/attinoutlogs'
export {default as reminderPage} from './reminder/reminder'
export {default as check_sheetPage} from './checksheet/checksheet'
