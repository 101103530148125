// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#accordion {
    height: 700px;
  }
  
  #accordion .header {
    font-size: 15px;
    
  }
  .dx-accordion-item-title{
    background-color: blue;
  }
  #accordion .header,
  #accordion p {
    margin: 0;
  }
  
  #accordion-container {
    margin-right: 1px;
  }
  .toolbarreminder{
    margin-bottom: 0;
  }
  .dx-theme-material #accordion .dx-accordion-item-title {
    display: flex;
    background-color: rgb(228, 54, 54);
    color: azure;
  }
  
  .dx-theme-material #accordion .header {
    align-self: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/reminder/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,eAAe;;EAEjB;EACA;IACE,sBAAsB;EACxB;EACA;;IAEE,SAAS;EACX;;EAEA;IACE,iBAAiB;EACnB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,aAAa;IACb,kCAAkC;IAClC,YAAY;EACd;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":["#accordion {\r\n    height: 700px;\r\n  }\r\n  \r\n  #accordion .header {\r\n    font-size: 15px;\r\n    \r\n  }\r\n  .dx-accordion-item-title{\r\n    background-color: blue;\r\n  }\r\n  #accordion .header,\r\n  #accordion p {\r\n    margin: 0;\r\n  }\r\n  \r\n  #accordion-container {\r\n    margin-right: 1px;\r\n  }\r\n  .toolbarreminder{\r\n    margin-bottom: 0;\r\n  }\r\n  .dx-theme-material #accordion .dx-accordion-item-title {\r\n    display: flex;\r\n    background-color: rgb(228, 54, 54);\r\n    color: azure;\r\n  }\r\n  \r\n  .dx-theme-material #accordion .header {\r\n    align-self: center;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
