import { HomePage, TasksPage, ProfilePage,ChangePasswordpage,Shiftmealpage,SalaAdvancepage,AnnuaLeavepage,Attinoutlogspage,reminderPage,check_sheetPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {path: '/tasks',element: TasksPage},
    {path: '/profile',element: ProfilePage},
    {path: '/home',element: HomePage},
    {path: '/changepass',element: ChangePasswordpage},
    {path: '/shiftmeal',element: Shiftmealpage},
    {path: '/sala_advance',element: SalaAdvancepage},
    {path: '/anualeave',element: AnnuaLeavepage},
    {path: '/attinoutlogs',element: Attinoutlogspage},
    {path: '/reminder',element: reminderPage},
    {path: '/checksheet',element: check_sheetPage},
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
