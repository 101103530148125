import React, { useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { createAccount } from '../../api/auth';

import './CreateAccountForm.scss';

export default function CreateAccountForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ username: '', password: '' });

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { em_code,em_idcard,em_phone, em_pass } = formData.current;
    setLoading(true);

    const result = await createAccount(em_code,em_idcard,em_phone, em_pass);
    setLoading(false);

    if (result.isOk) {
      navigate('/login');
    } else {
      notify(result.message, 'error', 2000);
    }
  }, [navigate]);

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.em_pass,
    []
  );

  return (
    <form className={'create-account-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'em_code'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message="Mã NV yêu cầu" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'em_idcard'}
          editorType={'dxTextBox'}
          editorOptions={cccdEditorOptions}
        >
          <RequiredRule message="Số CCCD yêu cầu" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'em_phone'}
          editorType={'dxTextBox'}
          editorOptions={phoneEditorOptions}
        >
          <RequiredRule message="Số điện thoại yêu cầu" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'em_pass'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={'Passwords do not match'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <Item>
          <div className='policy-info'>
            By creating an account, you agree to the <Link to="#">Terms of Service</Link> and <Link to="#">Privacy Policy</Link>
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Gửi Yêu Cầu.'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            Have an account? <Link to={'/login'}>Đăng nhập</Link>
          </div>
        </Item>
      </Form>
    </form>
  );
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Mã NV.' };
const cccdEditorOptions = { stylingMode: 'filled', placeholder: 'Số CCCD' };
const phoneEditorOptions = { stylingMode: 'filled', placeholder: 'Số điện thoại' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Mật khẩu', mode: 'password' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Xác nhận lại', mode: 'password' };
