// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-card .dx-card {
  width: 330px;
  margin: auto auto;
  padding: 40px;
  flex-grow: 0;
}
.screen-x-small .single-card .dx-card {
  width: 100%;
  height: 100%;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
  border: 0;
  flex-grow: 1;
}
.single-card .dx-card .header {
  margin-bottom: 30px;
}
.single-card .dx-card .header .title {
  color: rgba(0, 0, 0, 0.8705882353);
  line-height: 28px;
  font-weight: 500;
  font-size: 24px;
}
.single-card .dx-card .header .description {
  color: rgba(0, 0, 0, 0.6094117647);
  line-height: 18px;
}`, "",{"version":3,"sources":["webpack://./src/layouts/single-card/single-card.scss","webpack://./src/themes/generated/variables.base.scss"],"names":[],"mappings":"AAIE;EACE,YAAA;EACA,iBAAA;EACA,aAAA;EACA,YAAA;AAHJ;AAKI;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,SAAA;EACA,SAAA;EACA,YAAA;AAHN;AAMI;EACE,mBAAA;AAJN;AAMM;EACE,kCCvBU;EDwBV,iBAAA;EACA,gBAAA;EACA,eAAA;AAJR;AAOM;EACE,kCAAA;EACA,iBAAA;AALR","sourcesContent":["@import \"../../themes/generated/variables.base.scss\";\n\n.single-card {\n\n  .dx-card {\n    width: 330px;\n    margin: auto auto;\n    padding: 40px;\n    flex-grow: 0;\n\n    .screen-x-small & {\n      width: 100%;\n      height: 100%;\n      border-radius: 0;\n      box-shadow: none;\n      margin: 0;\n      border: 0;\n      flex-grow: 1;\n    }\n\n    .header {\n      margin-bottom: 30px;\n\n      .title {\n        color: $base-text-color;\n        line-height: 28px;\n        font-weight: 500;\n        font-size: 24px;\n      }\n\n      .description {\n        color: rgba($base-text-color, alpha($base-text-color) * 0.7);\n        line-height: 18px;\n      }\n    }\n  }\n}\n","$base-accent: #ff5722;\n$base-text-color: #000000de;\n$base-bg: #ffffff;\n$base-border-color: #e0e0e0;\n$base-border-radius: 4px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
