import React, { useState, createContext, useEffect,useContext,useCallback } from 'react';
import socket from '../api/socket_io';
import {getUser} from '../api/auth'
function Appcommon(props) {
  const [Reminder,setReminder] = useState({});
    useEffect(() => {
      getUser().then(res => {
        socket.emit('storeClientInfo',{username:res.data.username,token:res.data.accessToken})
      })
      .catch(err => {
        console.log(err);
      });
      let first = [];
      socket.on("reminder_Count", (data) => {
        let newItems = first;
        if (newItems.length >0){
          setReminder([...newItems,...data])
          first = [...newItems,...data]         
        } 
        else {
          setReminder(data);
          first = data;
        }    
      });
      socket.on("reminder_read", (data) => {
        let newItems = first;
        if (newItems.length >0){
          var objIndex = newItems.findIndex(obj => obj.re_id ==  Number(data.re_id));
          if (objIndex == -1){return;}
          newItems[objIndex].read = 1;
          var tempo = [];
          newItems = [...newItems,...tempo];
          setReminder(newItems);
          first = newItems
        }     
      });
      socket.on("reminder_callback", (data) => {
        let newItems = first;
        if (newItems.length >0){
          let updated = newItems.filter(remider => remider.re_id != Number(data[0].re_id));
          setReminder(updated);
          first = updated;
        }    
      });
    }, []);
    const disOut = useCallback(() => {
      socket.disconnect();
      setReminder([]);
    }, []);
    
  
    return <Commoninfor.Provider value={{Reminder,disOut}} {...props}/>
}
const Commoninfor = createContext({});
const UsecomInfor = () => useContext(Commoninfor);
export {Appcommon, UsecomInfor}
