import React from 'react';
function ChangeRead(data){
  if (data.read ==1){
    return './padlock-5-16.png';
  }
  else{
    return './padlock-7-16.png'
  }
}
export default function CustomTitle(data) {
  return  <div className="header">
                <img src = {ChangeRead(data)} alt="Girl in a jacket"></img>
                {data.re_subtitle}              
            </div>
}
