// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-password-form .submit-button {
  margin-top: 10px;
}
.reset-password-form .login-link {
  color: #ff5722;
  font-size: 16px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/reset-password-form/ResetPasswordForm.scss","webpack://./src/themes/generated/variables.base.scss"],"names":[],"mappings":"AAGE;EACE,gBAAA;AAFJ;AAKE;EACE,cCRU;EDSV,eAAA;EACA,kBAAA;AAHJ","sourcesContent":["@import \"../../themes/generated/variables.base.scss\";\n\n.reset-password-form {\n  .submit-button {\n    margin-top: 10px;\n  }\n\n  .login-link {\n    color: $base-accent;\n    font-size: 16px;\n    text-align: center;\n  }\n}\n","$base-accent: #ff5722;\n$base-text-color: #000000de;\n$base-bg: #ffffff;\n$base-border-color: #e0e0e0;\n$base-border-radius: 4px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
