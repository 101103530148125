import React, { useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  GroupItem,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { resetPassword } from '../../api/auth';
import './ResetPasswordForm.scss';

//const notificationText = 'We\'ve sent a link to reset your password. Check your inbox.';

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ em_code: '', em_idcard: '', em_phone: ''});

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { em_code,em_idcard,em_phone} = formData.current;
    setLoading(true);

    const result = await resetPassword(em_code,em_idcard,em_phone);
    setLoading(false);

    if (result.isOk) {
      navigate('/login');
      notify(result.message, 'success', 2500);
    } else {
      notify(result.message, 'error', 2000);
    }
  }, [navigate]);

  return (
    <form className={'reset-password-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'em_code'}
          editorType={'dxTextBox'}
          editorOptions={emcodeEditorOptions}
        >
          <RequiredRule message="Yêu cầu Mã NV." />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'em_idcard'}
          editorType={'dxTextBox'}
          editorOptions={emcccdEditorOptions}
        >
          <RequiredRule message="Yêu cầu CCCD." />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'em_phone'}
          editorType={'dxTextBox'}
          editorOptions={emphoneEditorOptions}
        >
          <RequiredRule message="Yêu cầu SĐT." />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            elementAttr={submitButtonAttributes}
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Gửi Yêu Cầu'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            Quay lại <Link to={'/login'}>Đăng nhập</Link>
          </div>
        </Item>
        <GroupItem>
          <label>Thời gian chấp nhận có thể mất từ 8 tới 24 giờ hoặc trợ giúp hotline :</label>
          <a href='19004567'>19004567</a>
        </GroupItem>
      </Form>
    </form>
  );
}

const emcodeEditorOptions = { stylingMode: 'filled', placeholder: 'Mã NV.', };
const emcccdEditorOptions = { stylingMode: 'filled', placeholder: 'Số CCCD', };
const emphoneEditorOptions = { stylingMode: 'filled', placeholder: 'Số ĐT.', };
const submitButtonAttributes = { class: 'submit-button' };
