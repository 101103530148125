import axios from "axios";
import socket from "./socket_io";
import server from './configss';

const API_URL = server + "/api/auth/";

export async function signIn(em_code, em_pass) {
  return axios
    .post(API_URL + "signin_em", {em_code, em_pass})
    .then((response) => {
      if (response.status === 400) {
        return {
          isOk: false,
          message: "Đăng nhập thất bại có lỗi kết nối"
        }
      }
      if (response.data.mode !==5 ) {
        return {
          isOk: false,
          message: response.data.message
        }
      }
      if (response.data.accessToken) {
        localStorage.setItem("userEm", JSON.stringify(response.data));
        return {
          isOk: true,
          data: response.data
        };
      }
      else {
        return {
          isOk: false,
          message: response.data.message
        };
      }
    }
    )
    .catch(function (error) {
      console.log(error,'loi')
      return {
        isOk: false,
        message: "Uer or password is failed please contact your Administrator"
      }
    })
}

export async function getUser() {
  const userEm = JSON.parse(localStorage.getItem("userEm"));
  try {
    // Send request
    if (userEm && userEm.accessToken) {
      return {
        isOk: true, // điều chỉnh để login
        data: userEm // có thể lấy data mặc định 
      };
    } else {
      return {
        isOk: false, // điều chỉnh để login
        data: userEm // có thể lấy data mặc định 
      };

    }
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(em_code,em_idcard,em_phone, em_pass) {
  return axios
    .post(API_URL + "signup_em", {em_code,em_idcard,em_phone, em_pass })
    .then((response) => {
      if (response.status === 400) {
        return {
          isOk: false,
          message: "Tạo tài khoản thất bại có lỗi kết nối"
        }
      }
      if (response.data.mode === 4) {
        return {
          isOk: true,
          message: response.data.message
        }
      }
      else {
        return {
          isOk: false,
          message: response.data.message
        };
      }
    }
    )
    .catch(function (error) {
      return {
        isOk: false,
        message: "System error! please contact to IT Administrator"
      }
    })
}

export async function changePassword(em_code,em_pass,em_newpass) {
  return axios
  .post(API_URL + "changepass_em", {em_code,em_pass,em_newpass})
  .then((response) => {
    if (response.status === 400) {
      return {
        isOk: false,
        message: "Đổi mật khẩu thất bại có lỗi kết nối"
      }
    }
    if (response.data.mode === 1) {
      return {
        isOk: true,
        message: response.data.message
      }
    }
    else {
      return {
        isOk: false,
        message: response.data.message
      };
    }
  }
  )
  .catch(function (error) {
    return {
      isOk: false,
      message: "Uer or password is failed please contact your Administrator"
    }
  })
}

export async function resetPassword(em_code,em_idcard,em_phone) {
  return axios
  .post(API_URL + "resetpass_em", {em_code,em_idcard,em_phone})
  .then((response) => {
    if (response.status === 400) {
      return {
        isOk: false,
        message: "Yêu cầu mật khẩu thất bại có lỗi kết nối"
      }
    }
    if (response.data.mode === 1) {
      return {
        isOk: true,
        message: response.data.message
      }
    }
    else {
      return {
        isOk: false,
        message: response.data.message
      };
    }
  }
  )
  .catch(function (error) {
    return {
      isOk: false,
      message: "System error . Please contact to administrator"
    }
  })
}
