import React,{useCallback,useRef,useState} from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {Column,Pager,Paging,FilterRow,Editing,Form,Item
} from 'devextreme-react/data-grid';
import server from '../../api/configss';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import authHeader from '../../api/auth-header';
import notify from 'devextreme/ui/notify';
import Gallery from 'devextreme-react/gallery';
import { Button } from 'devextreme-react/button';
import { Slider,Tooltip } from 'devextreme-react/slider';
import FileResizer from 'react-image-file-resizer';
import uploadFilesService from '../../services/uploadfile.service';
import {geturlImage} from '../../api/user_api'
const format = (value) => `${value}%`;
export default function CheckSheet() {
    const fileRef = useRef(null);
    const [urlimage , seturlimage] = useState(null);
    const [sliderValue, setSliderValue] = useState(0);
    const [imageup , setimageup] = useState(null);
    const [fileInfos , setfileInfos] = useState([]);
    const [ticket_code,setTicket_code] = useState(null);
    const notesEditorOptions = { height: 100 };
    const  selectFile =  React.useCallback(async(e) => {
        var fileInput = false;
        let files = e.target.files
        const ImageUrl = URL.createObjectURL(files[0]);
        if (e.target.files[0]) {
            fileInput = true;
        }
        if (fileInput) {
            try {
                FileResizer.imageFileResizer(e.target.files[0],700,1600,"JPEG",100,0,
                (fileimage) => {
                    seturlimage(ImageUrl);setimageup(fileimage);
                },
                "file"
                ,200,200);
            } catch (err) {
                console.log(err);
            }
        }
    },[]);
    const handleClick = React.useCallback(async(e) => {
        const fileElem = fileRef.current;
        if (fileElem) {
            fileElem.click();
        }
    },[]);
    const upload = React.useCallback(()=> {

        uploadFilesService.upload('/api/files/upload/em_filesupload',imageup,`calendarfile/${User().username}`,imageup.name
                                    ,fileName(ticket_code,imageup.name),ticket_code,'calendarfile',User().username,) 
            .then((response) => {
                notify(response.data.message, 'success', 2500);
                var obj= response.data.data;
                var result = obj.map(({url_image}) => url_image)      
                setfileInfos(result);  
            })
            .catch((e) => {
                console.log('loi',e)
                setfileInfos(undefined); 
            });
    },[imageup,ticket_code]);
    const onEditingStart= React.useCallback((e) => {
        if (e.data){
            setTicket_code(e.data.ID);
            geturlImage(e.data.ID, 'calendarfile').then((response) => {
                var obj= response.data.data;
                var result = obj.map(({url_image}) => url_image)      
                setfileInfos(result);
                setSliderValue(e.data.PercentComplete);  
            })
            .catch((e) => {
                console.log('loi',e)
                setfileInfos(undefined);
            });
        }
      },[setfileInfos]);
    const onValueChanged = useCallback((ec) => {
        setSliderValue(ec.value);
    },[setSliderValue]);

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Calendar & Report</h2>

      <DataGrid
        className={'dx-card wide-card'}
        dataSource={Shiftmeal_infor}
        showBorders={false}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true} onEditingStart={onEditingStart} onSaved={onSaved}
        dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <Editing
            mode="form"
            useIcons={true}
            allowUpdating={true}
            allowAdding ={false}
        >
            <Form showBorders="true">
                <Item itemType="group" caption="Hình ảnh đính kèm" colCount={2} colSpan={2} showBorders="true" >
                    <table>
                        <tbody>
                            <tr>
                                <th>
                                    <Gallery
                                        dataSource={fileInfos}
                                        height={300}width={400}
                                        slideshowDelay={2000}
                                        loop={false}
                                        showNavButtons={true} showIndicator={true} />

                                </th>
                            </tr>
                            <tr>
                                <th>
                                    <img src={urlimage} alt="Source:" width="60" height="40"></img>
                                    <br />
                                    <Button icon="camera-icon.png" text='Capture->' onClick={handleClick} stylingMode="contained" type='default'>Capture</Button>
                                    <input ref={fileRef} type="file" style={{ display: 'none' }} name="photo" accept="image/*;capture=camera" onChange={selectFile} />
                                    <Button disabled={!imageup} icon='check' text='Upload' stylingMode="contained" type="success" onClick={upload} />

                                </th>
                            </tr>
                        </tbody>
                    </table>
                </Item>
                <Item itemType="group" colCount={2} colSpan={2}>
                    <Item dataField="Subject" />
                    <Item dataField="Start" />
                    <Item dataField="End" />
                    <Item dataField="Location" />
                    <Item dataField="PercentComplete" />
                    <Item itemType = "group" >
                    <Slider min={0}
                            max={100}
                            value={sliderValue}
                            onValueChanged={onValueChanged} readOnly ={true}>
                        <Tooltip enabled={true} showMode="always" position="bottom" format={format} />
                    </Slider>
                </Item>
                    
                </Item>
                
                <Item colSpan ={2}  colCount = {2}
                     editorOptions = {notesEditorOptions} 
                     dataField="Description" />            
            </Form>
        </Editing>
                
        <Column 
            dataField={'ID'} 
            width={90} 
            visible ={false}
        />
        <Column 
            dataField={'StatusJob'} 
            width={90} 
            visible ={false}
        />
        <Column
          dataField={'Subject'}
          caption={'Job Name'}
          hidingPriority={1}
          editorOptions={editField}
        />
        <Column
          dataField={'Description'}
          caption={'Description'}
          hidingPriority={1}
        />
        <Column
          dataField={'Start'}
          caption={'Start'}
          hidingPriority={2}
          dataType="datetime" format="HH:mm dd-MM-yyyy"
          editorOptions={editField}
        />
        <Column
          dataField={'End'}
          caption={'Finished.'}
          hidingPriority={3}
          dataType="datetime" format="HH:mm dd-MM-yyyy"
          editorOptions={editField}
        />
        <Column
          dataField={'PercentComplete'}
          caption={'Completed %'}
          hidingPriority={4}
          editorOptions={numBer}
        >
        </Column>
        <Column
          dataField={'Location'}
          caption={'Location'}
          hidingPriority={5}
          format={AmountFormat}
          editorOptions={editField}
        />
      </DataGrid>
    </React.Fragment>
)}
function User() {
    try {
        return JSON.parse(localStorage.getItem("userEm"));
    }
    catch {
        return null;
    }
}
function fileName(url_code,Filename){
    const date = new Date();
    let dateName = date.getFullYear().toString() + pad2(date.getMonth() + 1) + pad2( date.getDate()) + pad2( date.getHours() ) + pad2( date.getMinutes() ) + pad2( date.getSeconds() ) 
   let newName = url_code + '_'+ dateName +'.'+ Filename.substr(Filename.lastIndexOf('.') + 1);
   return newName;
}
function pad2(n) { return n < 10 ? '0' + n : n }
const url = server + '/api/data/emcheck_sheet_calendar';
const editField = { readOnly: true }
const numBer = { dataType :'number' ,min:0,max:100}
const AmountFormat = { style: 'currency', currency: 'VND', useGrouping: true, minimumSignificantDigits: 3 };
function yyyymmdd(dateIn) {
    var yyyy = dateIn.getFullYear();
    var mm = dateIn.getMonth() + 1; // getMonth() is zero-based
    var dd = dateIn.getDate();
    return String(yyyy + "-" +  mm + "-" + dd); // Leading zeros for mm and dd
  }
const _Today  = yyyymmdd(new Date());
const Shiftmeal_infor = createStore({
    key: 'ID',
    loadUrl: `${url}`,
    insertUrl: `${url}insert`,
    deleteUrl: `${url}delete/`,
    updateUrl: `${url}put/`,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = authHeader();
        if (method === 'load') {
            ajaxOptions.url = `${url}/${User().username}/${_Today}`
        }
        if (method === 'insert') {
            ajaxOptions.data = JSON.parse(ajaxOptions.data.values)
        }
        if (method === 'update') {
            ajaxOptions.url += ajaxOptions.data.key;
            ajaxOptions.data = JSON.parse(ajaxOptions.data.values)
        }
        if (method === 'delete') {
            ajaxOptions.url += ajaxOptions.data.key;
        }
    }
}
);

const onSaved= async (e) => {
  /* const result = await salaAdvance(sala_id,User().username)
  if (result.isOk) {
    notify(result.message, 'success', 2500);
    window.location.reload();
  } else {
    notify(result.message, 'error', 2000);
  }  */  
};

