// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-account-form .policy-info {
  margin: 10px 0;
  color: rgba(0, 0, 0, 0.6094117647);
  font-size: 14px;
  font-style: normal;
}
.create-account-form .policy-info a {
  color: rgba(0, 0, 0, 0.6094117647);
}
.create-account-form .login-link {
  color: #ff5722;
  font-size: 16px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/create-account-form/CreateAccountForm.scss","webpack://./src/themes/generated/variables.base.scss"],"names":[],"mappings":"AAGE;EACE,cAAA;EACA,kCAAA;EACA,eAAA;EACA,kBAAA;AAFJ;AAII;EACE,kCAAA;AAFN;AAME;EACE,cCfU;EDgBV,eAAA;EACA,kBAAA;AAJJ","sourcesContent":["@import \"../../themes/generated/variables.base.scss\";\n\n.create-account-form {\n  .policy-info {\n    margin: 10px 0;\n    color: rgba($base-text-color, alpha($base-text-color) * 0.7);\n    font-size: 14px;\n    font-style: normal;\n\n    a {\n      color: rgba($base-text-color, alpha($base-text-color) * 0.7);\n    }\n  }\n\n  .login-link {\n    color: $base-accent;\n    font-size: 16px;\n    text-align: center;\n  }\n}\n","$base-accent: #ff5722;\n$base-text-color: #000000de;\n$base-bg: #ffffff;\n$base-border-color: #e0e0e0;\n$base-border-radius: 4px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
