import React, { useState,useEffect,useCallback }  from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {Column,Pager,Paging,FilterRow,Form,Item,Editing,RequiredRule,Lookup,
} from 'devextreme-react/data-grid';
import {Form as Formr,Item as Itemr,Label,GroupItem} from 'devextreme-react/form';
import server from '../../api/configss';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import CustomStore from 'devextreme/data/custom_store';
import axios from "axios";
import authHeader from '../../api/auth-header';
import notify from 'devextreme/ui/notify';
import {getAttabsum} from '../../api/user_api'


export default function Attinoutlogs() {
  const [employee_infor, setEmployee] = useState({});
  useEffect(() =>  {
    const fetchBusinesses = () => {
      getAttabsum(User().username).then(
        response => {
          setEmployee(response.data.data[0]);
        },
        error => {
          console.log(error.response)
        }
      );
    };
    fetchBusinesses();
  }, [setEmployee]);
  const onEditingStart= useCallback((e) => {
    e.data.ab_emcode = employee_infor.em_code;
    e.data.ab_user = User().username
    if (e.data.ab_status >0) {
      notify('Phép của bạn đã chuyển qua phê duyệt không thể sửa', 'error', 2000);
      e.cancel = true;
      return;
    }
  },[employee_infor]);
  const onRowRemoving= useCallback((e) => {
    if (e.data.ab_status > 0) {
      notify('Phép của bạn đã chuyển qua phê duyệt không thể xóa', 'error', 2000);
      e.cancel = true;
      return;
    }
  },[]);

  const onInitNewRow= useCallback((e) => {
    e.data.ab_emcode = employee_infor.em_code;
    e.data.ab_user = User().username;
  },[employee_infor]);
  return (
    <React.Fragment>

      <h2 className={'content-block'}>Bảng giờ công</h2>
      <div className={'content-block dx-card responsive-paddings'}>
        <Formr
          id={'form'}
          formData={employee_infor}
          labelLocation={'top'}
          colCountByScreen={colCountByScreen}
        >
          
            <Itemr dataField={'em_code'} editorType={'dxTextBox'} editorOptions={editField}
            >
              <Label text='Mã NV.'/>
            </Itemr>
            <Itemr dataField={'em_fullname'} editorType={'dxTextBox'}editorOptions={editField}
              >
              <Label text='Tên NV.'/>
            </Itemr>
            <GroupItem caption=' Đã nghỉ trong năm' colCount={1} colSpan={2}>
              <DataGrid 
              showBorders = {false} columnAutoWidth={true} showColumnHeaders ={false}
              dataSource={sumabsent}
              >
                <Column dataField='ab_type'>
                <Lookup dataSource={dataType} valueExpr="ID" displayExpr="Name" />
                </Column>
                <Column dataField='duration'/>
              </DataGrid>
          </GroupItem>
        </Formr>
      </div>
      <div>
        <DataGrid
          className={'dx-card wide-card'}
          dataSource={anualleave_infor}
          showBorders={false}
          focusedRowEnabled={true}
          defaultFocusedRowIndex={0}
          columnAutoWidth={true}
          columnHidingEnabled={true} onInitNewRow={onInitNewRow} onEditingStart={onEditingStart} onRowRemoving={onRowRemoving}
          dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
        >
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={true} />
          <Editing
              mode="form"
              useIcons={true}
              allowUpdating={true}
          >
              <Form showBorders="true" id={'form1'} colCount={1}>
                  <Item itemType="group" colCount={2} colSpan={1} caption={'Yêu cầu xác thực giờ công'}>
                      <Item dataField="att_emcode" />
                      <Item dataField="att_date" />
                      <Item dataField="att_timein_re"/>
                      <Item dataField="att_timeout_re" />
                      <Item dataField="att_reason_re" colSpan = {2}
                        editorOptions ={notesEditorOptions}/>
                      <Item dataField="att_username_re" />
                  </Item>
              </Form>
          </Editing>
                  
          <Column
            dataField={'att_emcode'}
            width={90}
            caption={'Mã NV'}
            hidingPriority={1}
            editorOptions={editField}
          />
          <Column
            dataField={'em_fullname'}
            caption={'Họ tên'}
            hidingPriority={2}
            editorOptions={editField}
          />
          <Column
            dataField={'att_date'} dataType="date" format="dd-MM-yyyy" caption={'Ngày'} hidingPriority={6} editorOptions={editField}/>
          <Column
            dataField={'att_timein'}
            caption={'Vào'}
            hidingPriority={7}
            dataType="date" format="HH:mm dd-MM-yy"
            
            >
            </Column>
          <Column
            dataField={'att_timeout'}
            caption={'Ra'}
            hidingPriority={8}
            dataType="date" format="HH:mm dd-MM-yy"
            >
            </Column>
            <Column
            dataField={'att_timein_re'} visible = {false}
            caption={'Giờ Vào'}
            dataType="datetime" format="HH:mm dd-MM-yy"
            >
            </Column>
            <Column
            dataField={'att_timeout_re'} visible = {false}
            caption={'Giờ Ra'}
            dataType="datetime" format="HH:mm dd-MM-yy"
            >
            </Column>
            <Column
            dataField={'att_reason_re'} visible = {false}
            caption={'Lý Do'}
            >
            <RequiredRule/>
            </Column>
            <Column
            dataField={'att_username_re'} visible = {false}
            caption={'Duyệt Bởi'}
            hidingPriority={10}
            >
            <RequiredRule/>
            </Column>
        </DataGrid>
      </div>
    </React.Fragment>
)}
function User() {
    try {
        return JSON.parse(localStorage.getItem("userEm"));
    }
    catch {
        return null;
    }
}
const url = server + '/api/data/getatt_inoutlogs_em';
const urlsumab = server + '/api/data/att_absentsum';
const editField = { readOnly: true };
const notesEditorOptions = { height: 100 };
const anualleave_infor = new CustomStore({
  key : 'att_id',
  load :()=>{
    return axios.get(url + `/${User().username}`, { headers: authHeader() })
    .then(
      response => {
        return response.data.data;
      },
      error => {
        console.log(error.response)
      }
    );
  },
  insert: async (values) => {
    return axios.post(`${url}insert`, values,{ headers: authHeader() })
    .then(handleErrors);
  },
  update: async (key, values) => {
    console.log(key,values)
    return axios.put(`${url}put/${key}`, values,{ headers: authHeader() })
    .then(handleErrors);
  },
  remove: async (key) => {
    return axios.delete(`${url}delete/${key}`,{ headers: authHeader() })
    .then(handleErrors);
  },
});
function handleErrors(response) {

  if (response.data.model ===5) {
    notify(response.data.message, 'success', 2500)
    return;
  }
  else {
    notify(response.data.message, 'error', 2500)
  }
}
const sumabsent = createStore({
  loadUrl: `${url}`,
  onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = authHeader();
      if (method === 'load') {
          ajaxOptions.url = `${urlsumab}/${User().username}`
      }
  }
}
);
const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4
};
const dataType = [
  { ID: 1, Name: 'Phép năm', }, { ID: 2, Name: 'Bệnh', },
  { ID: 3, Name: 'Thai sản', }, { ID: 4, Name: 'Không lương', },
  { ID: 5, Name: 'Công tác', }, { ID: 6, Name: 'Đào tạo', },
  { ID: 7, Name: 'Khác', },
]
