import React, { useMemo, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import {UsecomInfor} from '../../contexts/commonContex'
import './UserPanel.scss';
export default function UserPanel({ menuMode }) {
  const { user, signOut } = useAuth();
  const disOut= UsecomInfor().disOut;
  const navigate = useNavigate();
  const avanter = './userface.png'
  const navigateToProfile = useCallback(() => {
    navigate("/profile");
  }, [navigate]);
  const changPass = useCallback(() => {
    navigate("/changepass");
  }, [navigate]);
  const LogOut = useCallback(async() => {
    signOut();
    await disOut();
  }, []);
  const menuItems = useMemo(() => ([
    {
      text: 'Profile',
      icon: 'user',
      onClick: navigateToProfile
    },
    {
      text: 'Đổi mật khẩu',
      icon: 'key',
      onClick: changPass
    },
    {
      text: 'Thoát',
      icon: 'runner',
      onClick: LogOut
    }
  ]), [navigateToProfile,changPass, signOut]);
  return (
      <div className={'user-panel'}>
        <div className={'user-info'}>
          <div className={'image-container'}>
            <div
              style={{
                background: `url(${avanter}) no-repeat #fff`,
                backgroundSize: 'cover'
              }}
              className={'user-image'} />
          </div>
          <div className={'user-name'}>{user.username}</div>
        </div>

        {menuMode === 'context' && (
          <ContextMenu
            items={menuItems}
            target={'.user-button'}
            showEvent={'dxclick'}
            width={210}
            cssClass={'user-menu'}
          >
            <Position my={{ x: 'center', y: 'top' }} at={{ x: 'center', y: 'bottom' }} />
          </ContextMenu>
        )}
        {menuMode === 'list' && (
          <List className={'dx-toolbar-menu-action'} items={menuItems} />
        )}
      </div>
  );
}
