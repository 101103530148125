import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest,changePassword } from '../api/auth';


function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
      }
      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (em_code, em_pass) => {
    const result = await sendSignInRequest(em_code, em_pass);
    if (result.isOk) {
      setUser(result.data);
    }

    return result;
  }, []);
  const changePass = useCallback(async (em_code, em_pass,em_newpass) => {
    const result = await changePassword(em_code, em_pass,em_newpass);
    if (result.isOk) {
      setUser(undefined);
      localStorage.clear();
    }
    return result;
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
    localStorage.clear();
  }, []);
  return (
    <AuthContext.Provider value={{ user, signIn, signOut,changePass, loading}} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
